h1 {
  font-family: "Red Rose", cursive !important;
}
h2 {
  font-family: "Red Rose", cursive !important;
}
h3 {
  font-family: "Red Rose", cursive !important;
}
h4 {
  font-family: "Red Rose", cursive !important;
}
h5 {
  font-family: "Red Rose", cursive !important;
}
.coin_list {
  background-color: #f1faff;
  padding: 15px;
  border-radius: 10px;
}
.coin_list h5 {
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  small {
    font-size: 14px;
  }
  img {
    width: 40px;
    margin-right: 10px;
  }
}
.send_icon {
  background-color: red !important;
  transform: rotate(45deg);
}
.resive_icon {
  background-color: green !important;
  color: #fff;
  transform: rotate(45deg);
}
.send_amount {
  color: red;
}

.resive_amount {
  color: green;
}
.moniwalet p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #2f2f2f;
}
div#form-dialog-title h2 {
  font-size: 23px;
  color: black;
}
#alert-dialog-title h2 {
  font-size: 23px;
  color: black;
}
.customForm p {
  font-size: 14px;
  svg {
    cursor: pointer;
  }
}

.mainBox-profile {
  padding: 15px;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 100px;
  }
}
.logoutBTn {
  font-size: 14px !important;
  color: #000 !important;
  svg {
    color: #000;
  }
  &:hover {
    color: #e03128;
  }
}
p#alert-dialog-description {
  color: #494949;
  margin: 20px 0;
  font-size: 16px;
}

.side-navigation-panel-select-option span {
  color: #9f9f9f;
  font-size: 15px;
  svg {
    font-size: 20px;
  }
}
.side-navigation-panel-select-option-selected {
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  backdrop-filter: blur(42px) !important;
  span {
    color: #fff !important;
  }
}
.side-navigation-panel-select-option:hover {
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  backdrop-filter: blur(42px) !important;
  span {
    color: #fff !important;
  }
}
// .side-navigation-panel *, .side-navigation-panel:after, .side-navigation-panel:before {
//     border-color: #E03128 !important;
// }
.side-navigation-panel-select-inner-option {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 40px !important;

  svg {
    font-size: 20px !important;
  }
}
.side-navigation-panel-select-inner-option span {
  color: #9f9f9f;
  font-size: 15px !important;
}
.side-navigation-panel-select-inner-option {
  &:hover {
    background: linear-gradient(
      152.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ) !important;
    filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
    backdrop-filter: blur(42px) !important;
    span {
      color: #fff !important;
    }
  }
}
.side-navigation-panel-select-inner-option-selected {
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  backdrop-filter: blur(42px) !important;
  span {
    color: #fff !important;
  }
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: #15161c !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  padding: 20px !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  border-color: #e03128 !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover {
  border-color: #e03128 !important;
}
nav.side-navigation-panel {
  margin-top: 50px;
}

.mainbox2:hover {
  transform: scale(1.1);
}
.mainbox2 {
  transition: 0.3s;
}
p.labelText {
  font-size: 14px;
  color: #000;
}
.resive_icon2 {
  background-color: green !important;
  border: 1px solid green !important;
  color: #fff !important;
  &:hover {
    background-color: #fff !important;
    border-color: green;
    color: green !important;
  }
}
.send_icon2 {
  color: #fff !important;
  margin-left: 5px !important;
  border: 1px solid red !important;
  background-color: red !important;
  &:hover {
    background-color: #fff !important;
    border-color: red;
    color: red !important;
  }
}
.transactin_icon {
  margin-left: 5px !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
  &:hover {
    background-color: #fff !important;
    border-color: black;
    color: black !important;
  }
}
.coin_list2 h6 {
  font-size: 16px;
  color: #5d5d5d;
  margin-bottom: 5px;
}
.notificationBox h5 {
  margin-top: 5px;
  margin-bottom: 10px;
}
.notificationBox p {
  font-size: 16px;
}
.slick-slide:last-child label::before {
  display: none;
}