body {
  font-family: "Roboto", sans-serif;
  background-color: #fff !important;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 14px;
  background-color: #fff;
}

.height100 {
  height: 100%;
}

.padding10 {
  padding: 10px 0;
}

.extra-bold {
  font-weight: 700 !important;
}

.coin_list {
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.coin_list .MuiTypography-body2 span {
  font-size: 17px;
  font-weight: 600;
}

.coin_list .MuiTypography-body2 {
  color: red;
}

.coin_list .MuiTypography-body2 span {
  font-size: 17px;
  font-weight: 600;
}

.coin_list .MuiTypography-body1 span {
  font-size: 13px;
  font-weight: 300;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.d-flex.justify-space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.d-flex.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.d-flex.alignstart {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.d-flex.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.step_bar {
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
}

.completed h5,
.activeStep h5 {
  width: 33px;
  height: 33px;
  background: #fff;
  position: absolute;
  z-index: 1;
  text-align: center;
  border-radius: 50%;
  left: -4px;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.completed {
  height: 30px;
  width: 30px;
  border: 2px solid #00e0b0;
  border-radius: 50%;
  margin: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  background-color: #fff;
}

.completed::before {
  content: " ";
  position: absolute;
  top: -200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 200px;
  width: 2px;
  background: #00e0b0;
}

.activeStep {
  height: 30px;
  width: 30px;
  border: 2px solid #0d2913;
  border-radius: 50%;
  margin: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  background-color: #fff;
  z-index: 999;
}

.left-nav .MuiButton-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.page-heading {
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 54px;
}

.page-heading2 {
  font-weight: 400 !important;
  font-size: 30px !important;
}

.text-white {
  color: #fff;
}

.customForm {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #efecec;
  background-color: #ededf1;
}

.customForm.MuiTextField-root {
  margin: 20px 0;
}

.customFormh5 {
  margin: 10px 0;
}

.rightButton {
  float: right;
}

.leftButton {
  float: left;
}

.dzu-dropzone {
  overflow: auto !important;
}

.rightPosition {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
}

.notication-list a {
  color: #000;
}

.width120 {
  width: calc(100% - 150px);
}

.ellispsys {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: pre;
  width: calc(100% - 30px);
}

@media screen and (max-width: 768px) {
  .step_bar {
    height: 300px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
            box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  .width120 {
    width: 100%;
    margin-bottom: 20px;
  }
  .rightPosition {
    position: absolute;
    top: auto;
    bottom: -5px;
    right: auto;
    left: 55px;
  }
}

.side_nev_Bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.loginForm {
  width: 90%;
  max-width: 600px;
}

.fullwidth {
  width: 100%;
}

.faqBg {
  height: 150px;
  background: url("https://dummyimage.com/600x400/00ab99/00ab99");
  border-radius: 12px;
}

.mainbox {
  border: 1px solid transparent;
  height: auto;
  padding: 11px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  max-width: 100%;
  background-color: #000 !important;
}

.mainbox1 {
  height: 97px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.overlay {
  position: relative;
  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.overlay:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.overlay h1 {
  font-size: 19px;
  font-weight: bold;
  line-height: 30px;
  color: #000;
  padding: 0 20px;
}

.overlay h4 {
  font-size: 15px;
  font-weight: normal;
  line-height: 30px;
  padding: 0 20px;
  color: #fff;
}

.overlay p {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #fff;
  padding: 0 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 19px;
}

.overlay figure {
  max-width: 350px;
  margin: 0 auto;
}

.overlay figure img {
  width: 100%;
  display: block;
}

.teamtext-main {
  margin-top: 15px;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.tokenSpecsList {
  padding-left: 0px;
  margin-bottom: 20px;
}

.tokenSpecsList li {
  list-style: none;
  position: relative;
  color: #9f9f9f;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.listview li {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  padding-left: 15px;
}

.listview li::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 1px;
  background-color: #fff;
  border: 3px solid #fff;
  z-index: 999;
  border-radius: 50%;
  left: 0;
  top: 13px;
}

.listviewAbout li {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  padding-left: 15px;
}

.listviewAbout li::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 1px;
  background-color: #000;
  border: 3px solid #000;
  z-index: 999;
  border-radius: 50%;
  left: 0;
  top: 13px;
}

h1 {
  font-family: "Red Rose", cursive !important;
}

h2 {
  font-family: "Red Rose", cursive !important;
}

h3 {
  font-family: "Red Rose", cursive !important;
}

h4 {
  font-family: "Red Rose", cursive !important;
}

h5 {
  font-family: "Red Rose", cursive !important;
}

.coin_list {
  background-color: #f1faff;
  padding: 15px;
  border-radius: 10px;
}

.coin_list h5 {
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.coin_list h5 small {
  font-size: 14px;
}

.coin_list h5 img {
  width: 40px;
  margin-right: 10px;
}

.send_icon {
  background-color: red !important;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.resive_icon {
  background-color: green !important;
  color: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.send_amount {
  color: red;
}

.resive_amount {
  color: green;
}

.moniwalet p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #2f2f2f;
}

div#form-dialog-title h2 {
  font-size: 23px;
  color: black;
}

#alert-dialog-title h2 {
  font-size: 23px;
  color: black;
}

.customForm p {
  font-size: 14px;
}

.customForm p svg {
  cursor: pointer;
}

.mainBox-profile {
  padding: 15px;
}

.mainBox-profile p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 100px;
}

.logoutBTn {
  font-size: 14px !important;
  color: #000 !important;
}

.logoutBTn svg {
  color: #000;
}

.logoutBTn:hover {
  color: #e03128;
}

p#alert-dialog-description {
  color: #494949;
  margin: 20px 0;
  font-size: 16px;
}

.side-navigation-panel-select-option span {
  color: #9f9f9f;
  font-size: 15px;
}

.side-navigation-panel-select-option span svg {
  font-size: 20px;
}

.side-navigation-panel-select-option-selected {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
  -webkit-filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  -webkit-backdrop-filter: blur(42px) !important;
          backdrop-filter: blur(42px) !important;
}

.side-navigation-panel-select-option-selected span {
  color: #fff !important;
}

.side-navigation-panel-select-option:hover {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
  -webkit-filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  -webkit-backdrop-filter: blur(42px) !important;
          backdrop-filter: blur(42px) !important;
}

.side-navigation-panel-select-option:hover span {
  color: #fff !important;
}

.side-navigation-panel-select-inner-option {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 40px !important;
}

.side-navigation-panel-select-inner-option svg {
  font-size: 20px !important;
}

.side-navigation-panel-select-inner-option span {
  color: #9f9f9f;
  font-size: 15px !important;
}

.side-navigation-panel-select-inner-option:hover {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
  -webkit-filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  -webkit-backdrop-filter: blur(42px) !important;
          backdrop-filter: blur(42px) !important;
}

.side-navigation-panel-select-inner-option:hover span {
  color: #fff !important;
}

.side-navigation-panel-select-inner-option-selected {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
  -webkit-filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25)) !important;
  -webkit-backdrop-filter: blur(42px) !important;
          backdrop-filter: blur(42px) !important;
}

.side-navigation-panel-select-inner-option-selected span {
  color: #fff !important;
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: #15161c !important;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option {
  padding: 20px !important;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option-selected {
  border-color: #e03128 !important;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option:hover {
  border-color: #e03128 !important;
}

nav.side-navigation-panel {
  margin-top: 50px;
}

.mainbox2:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.mainbox2 {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

p.labelText {
  font-size: 14px;
  color: #000;
}

.resive_icon2 {
  background-color: green !important;
  border: 1px solid green !important;
  color: #fff !important;
}

.resive_icon2:hover {
  background-color: #fff !important;
  border-color: green;
  color: green !important;
}

.send_icon2 {
  color: #fff !important;
  margin-left: 5px !important;
  border: 1px solid red !important;
  background-color: red !important;
}

.send_icon2:hover {
  background-color: #fff !important;
  border-color: red;
  color: red !important;
}

.transactin_icon {
  margin-left: 5px !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
}

.transactin_icon:hover {
  background-color: #fff !important;
  border-color: black;
  color: black !important;
}

.coin_list2 h6 {
  font-size: 16px;
  color: #5d5d5d;
  margin-bottom: 5px;
}

.notificationBox h5 {
  margin-top: 5px;
  margin-bottom: 10px;
}

.notificationBox p {
  font-size: 16px;
}

.slick-slide:last-child label::before {
  display: none;
}
