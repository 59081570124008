body {
  font-family: "Roboto", sans-serif;
  background-color: #fff !important;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
:root {
  font-size: 14px;
  background-color: #fff;
}
.height100 {
  height: 100%;
}
.padding10 {
  padding: 10px 0;
}
.extra-bold {
  font-weight: 700 !important;
}
.coin_list {
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  .MuiTypography-body2 span {
    font-size: 17px;
    font-weight: 600;
  }
  .MuiTypography-body2 {
    color: red;
  }
  .MuiTypography-body2 span {
    font-size: 17px;
    font-weight: 600;
  }
  .MuiTypography-body1 span {
    font-size: 13px;
    font-weight: 300;
  }
}
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.alignstart {
    align-items: flex-start !important;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}
.step_bar {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
}
.completed,
.activeStep {
  h5 {
    width: 33px;
    height: 33px;
    background: #fff;
    position: absolute;
    z-index: 1;
    text-align: center;
    border-radius: 50%;
    left: -4px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.completed {
  height: 30px;
  width: 30px;
  border: 2px solid #00e0b0;
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
}
.completed::before {
  content: " ";
  position: absolute;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  width: 2px;
  background: #00e0b0;
}
.activeStep {
  height: 30px;
  width: 30px;
  border: 2px solid rgb(13, 41, 19);
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  z-index: 999;
}
.left-nav {
  .MuiButton-label {
    display: flex;
    flex-direction: column;
  }
}
.page-heading {
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 54px;
}
.page-heading2 {
  font-weight: 400 !important;
  font-size: 30px !important;
}

.text-white {
  color: #fff;
}

.customForm {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #efecec;
  background-color: #ededf1;
  &.MuiTextField-root {
    margin: 20px 0;
  }
  &h5 {
    margin: 10px 0;
  }
}

.rightButton {
  float: right;
}

.leftButton {
  float: left;
}

.dzu-dropzone {
  overflow: auto !important;
}
.rightPosition {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.notication-list {
  a {
    color: #000;
  }
}
.width120 {
  width: calc(100% - 150px);
}
.ellispsys {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: pre;
  width: calc(100% - 30px);
}
@media screen and (max-width: 768px) {
  .step_bar {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  .width120 {
    width: 100%;
    margin-bottom: 20px;
  }
  .rightPosition {
    position: absolute;
    top: auto;
    bottom: -5px;

    right: auto;
    left: 55px;
  }
}
.side_nev_Bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.loginForm {
  width: 90%;
  max-width: 600px;
}
.fullwidth {
  width: 100%;
}
.faqBg {
  height: 150px;
  background: url("https://dummyimage.com/600x400/00ab99/00ab99");
  border-radius: 12px;
}

.mainbox {
  border: 1px solid transparent;
  height: auto;
  padding: 11px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  max-width: 100%;
  // background: radial-gradient(40.61% 45.14% at 14.73% 59.39%, rgb(27 100 111 / 34%) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(44.29% 56.68% at 92.02% 49.97%, rgb(238 29 35 / 18%) 0%, rgba(0, 0, 0, 0) 100%);
  // box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 10%);
  background-color: #000 !important;
}
.mainbox1 {
  height: 97px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: relative;
  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
  h1 {
    font-size: 19px;
    font-weight: bold;
    line-height: 30px;
    color: #000;
    padding: 0 20px;
  }

  h4 {
    font-size: 15px;
    font-weight: normal;
    line-height: 30px;
    padding: 0 20px;
    color: #fff;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #fff;
    padding: 0 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: 19px;
  }
  figure {
    // border-radius: 50%;
    max-width: 350px;
    margin: 0 auto;
    // background-color: #bebeca;
    img {
      width: 100%;
      display: block;
    }
  }
}

.teamtext-main {
  margin-top: 15px;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.tokenSpecsList {
  padding-left: 0px;
  margin-bottom: 20px;
}

.tokenSpecsList li {
  list-style: none;
  position: relative;
  color: #9f9f9f;
  font-size: 14px;
  display: flex;
  align-items: start;
}


.listview{
li{
  color: #fff;
font-size: 16px;
font-weight: 300;
position: relative;
padding-left: 15px;
&::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 1px;
  background-color: #fff;
  border: 3px solid #fff;
  z-index: 999;
  border-radius: 50%;
  left: 0;
  top: 13px;
}
}
}
.listviewAbout{
  li{
    color: #000;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  padding-left: 15px;
  &::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 1px;
    background-color: #000;
    border: 3px solid #000;
    z-index: 999;
    border-radius: 50%;
    left: 0;
    top: 13px;
  }
  }
  }